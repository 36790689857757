/* eslint no-shadow: ["error", { "allow": ["tabs"] }] */

import Vue from "vue";
import Vuex from "vuex";
import tabs from "./modules/tabsStore";
import tabModule from "./modules/tabModule";
import user from "./modules/userStore";
import lesson from "./modules/lessonStore";
import video from "./modules/videoStore";
import book from "./modules/bookStore";
import coupon from "./modules/couponStore";
import videoBank from "./modules/videoBankStore";
import bookBank from "./modules/bookBankStore";
import exercise from "./modules/exerciseStore";
import law from "./modules/lawStore";
import lawCollection from "./modules/lawCollectionStore";
import verdictSummary from "./modules/verdictSummaryStore";
import verdict from "./modules/verdictStore";
import group from "./modules/groupStore";
import exerciseAnswer from "./modules/exerciseAnswerStore";
import request from "./modules/requestStore";
import question from "./modules/questionStore";
import expoChapter from "./modules/expoChapterStore";
import expoChapterQuestion from "./modules/expoChapterQuestionStore";
import lecturesRegion from "./modules/lecturesRegionStore";
import lecturesHall from "./modules/lecturesHallStore";
import lecturesTeacher from "./modules/lecturesTeacherStore";
import lecturesFeedbackStatement from "./modules/lecturesFeedbackStatementStore";
import lecture from "./modules/lectureStore";
import lecturesLearningTrack from "./modules/lecturesLearningTrackStore";
import lectureTrackSubject from "./modules/lectureTrackSubjectStore";
import auth from "./modules/authStore";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  strict: debug,
  modules: {
    auth,
    tabs,
    user,
    lesson,
    video,
    book,
    coupon,
    videoBank,
    bookBank,
    exercise,
    law,
    lawCollection,
    verdictSummary,
    verdict,
    group,
    exerciseAnswer,
    request,
    question,
    expoChapter,
    expoChapterQuestion,
    lecturesRegion,
    lecturesHall,
    lecturesTeacher,
    lecturesFeedbackStatement,
    lecture,
    lecturesLearningTrack,
    lectureTrackSubject,
  }
});

const registerModule = ({ module }) => {
  // register a module `myModule`
  store.registerModule(["tabs", module], tabModule);
};

export default store;

export { registerModule };
