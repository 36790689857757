import { getField, updateField } from 'vuex-map-fields';
import CoursesService from '@/services/CoursesService';

const getDefaultState = function () {
    return {
        id: null,
        description: null,
        allowedPublication: null,
        courseId: null,
        createdDate: null,
        exerciseId: null,
        fullname: null,
        isUserSatisfied: null,
        lessonId: null,
        messagesInCourseTypesId: null,
        questionId: null,
        questionSubject: null,
        sentFromPage: null,
        taggedAdmins: null,
        status: null,
    };
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = getDefaultState();

const getters = {
    getField,
    form: (state) => ({
        id: state.id,
        description: state.description,
        allowedPublication: state.allowedPublication,
        courseId: state.courseId,
        createdDate: state.createdDate,
        exerciseId: state.exerciseId,
        fullname: state.fullname,
        isUserSatisfied: state.isUserSatisfied,
        lessonId: state.lessonId,
        messagesInCourseTypesId: state.messagesInCourseTypesId,
        questionId: state.questionId,
        questionSubject: state.questionSubject,
        sentFromPage: state.sentFromPage,
        taggedAdmins: state.taggedAdmins,
        status: state.status,
    }),
};

const mutations = {
    setRequest(state, value) {
        if (value) {
            // value.Transfers.forEach((status) => {
            //     status.Events.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            // });
            Object.assign(state, value);
        } else {
            Object.assign(state, getDefaultState());
        }
    },

    storeReportDetails(state, data) {
        Object.assign(state, data);
    },
    updateField,
};

const actions = {
    async loadRequest({ commit }, data) {
        const course = JSON.parse(localStorage.getItem('course'));
        const res = await CoursesService.getRequestsByCourse(course.id, {
            EntityId: data.id
        });
        commit('setRequest', res.data.items[0]);
        return res.data;
    },
    async loadRequestAdmin({ commit }, data) {
        const res = await CoursesService.getReportAdmin(data.id);
        commit('setRequest', res.data);
        return res.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
