import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const authorize = (model) => axios.post(`${BASE_URL}/Token`, model, {
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
});
const getRefreshToken = (refreshToken) => axios.post(`${BASE_URL}/refreshToken`, refreshToken);
const logout = () => axios.post(`${BASE_URL}/api/Account/Logout`);
const register = (model) => axios.post(`${BASE_URL}/api/Account/Register`, model);

export default {
    authorize,
    getRefreshToken,
    logout,
    register,
};
