import Vue from "vue";
import Buefy, { ConfigProgrammatic } from "buefy";
import VueMask from 'v-mask';
import VueTheMask from "vue-the-mask";
import Vuelidate from "vuelidate";
import axios from 'axios';
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import auth from "./auth";
import "buefy/dist/buefy.css";
import "../public/reset.css";
import "../public/rtl.css";
import "../public/animations.css";
import "./interceptor";

Vue.use(auth, { store });
Vue.use(Buefy);
Vue.use(CKEditor);
Vue.use(VueMask);
ConfigProgrammatic.setOptions({
  defaultProgrammaticPromise: true
});
Vue.use(VueTheMask);
Vue.use(Vuelidate);
Vue.use(VueFilterDateFormat);

Vue.config.productionTip = false;
Vue.prototype.$api = axios;

new Vue({
  router,
  store,
  // eslint-disable-next-line arrow-parens
  render: h => h(App)
}).$mount("#app");
