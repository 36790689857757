<template>
  <div id="app">
    <component :class="[isPrint && 'print']"  :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
  import LayoutInApp from '@/layouts/LayoutInApp.vue';
  import LayoutFull from '@/layouts/LayoutFull.vue';

  export default {
    name: 'App',
    components: {
      LayoutInApp,
      LayoutFull,
    },
    created() {
      this.cssPerSubdomain();
    },
    computed: {
      layout() {
        if (this.$route.meta.layout) return this.$route.meta.layout;
        if (this.$auth.isAuthenticated()) return 'layout-in-app';
        // return this.$route.meta.layout || 'layout-in-app';
        return false;
      },
      isPrint() {
        return this.$route.name === "ExercisePrint";
      }
    },
    methods: {
      cssPerSubdomain() {
        switch (window.location.host) {
          default:
            break;
        }
      },
    },
  };
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.button.is-primary {
  background-color:#ff7a59 !important;
  color: white !important;
  border-color: white !important;
}

.switch input[type=checkbox]:checked + .check {
    background: #ff7a59 !important;
}
.pagination-link.is-current {
  background-color: #00afd8 !important;
    border-color: #00afd8 !important;
}
.b-checkbox.checkbox input[type=checkbox]:checked + .check {
  background-color: #00afd8 !important;
    border-color: #00afd8 !important;
}

.check {
  direction: ltr;
  margin-left: 5px;
}

.b-tooltip.is-primary .tooltip-content {
  background: #ff7a59 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.datepicker > div > div > div > div {
    direction: ltr !important;
  }
  .print {
    .LayoutInApp__Component {
      height: 100%;
    }
  }
</style>
