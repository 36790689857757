import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const getLesson = (courseId, lessonId) => axios.get(`${BASE_URL}/api/admin/course/${courseId}/lessons/${lessonId}`);

const updateLesson = (lessonId, data) => axios.put(`${BASE_URL}/api/lessons/${lessonId}`, data);

const addLesson = (courseId, data) => axios.post(`${BASE_URL}/api/admin/course/${courseId}/lessons/add`, data);

const getAdminVideos = (lessonId, data) => axios.post(`${BASE_URL}/api/admin/lessonvideos/courses/${lessonId}`, data);

const updateVideo = (courseId, videoId, data) => axios.put(`${BASE_URL}/api/admin/lessonvideos/courses/${courseId}/update/${videoId}`, data);

const getVideoFeedback = (videoId) => axios.get(`${BASE_URL}/api/admin/lessonvideos/${videoId}/feedback`);

const getAdminBooks = (courseId, data) => axios.post(`${BASE_URL}/api/Admin/Books/Courses/${courseId}/Search`, data);

const getLessonBooks = (lessonId, data) => axios.post(`${BASE_URL}/api/admin/books/lessons/${lessonId}/Search`, data);

const updateCourseBook = (bookId, lessonId, data) => axios.put(`${BASE_URL}/api/Admin/Books/${bookId}/Lessons/${lessonId}`, data);

const addLessonVideo = (lessonId, data) => axios.post(`${BASE_URL}/api/Admin/LessonVideos/Courses/${lessonId}/Lessons/Add`, data);

const addLessonBook = (bookId, courseId, data) => axios.post(`${BASE_URL}/api/Admin/Books/${bookId}/lessons/${courseId}`, data);

const getAdminExercises = (lessonId, data) => axios.post(`${BASE_URL}/api/admin/exercises/courses/${lessonId}`, data);

const getExercise = (exerciseId, courseId) => axios.get(`${BASE_URL}/api/admin/exercises/${exerciseId}/courses/${courseId}/content`);

const updateExerciseContent = (exerciseId, courseId, data) => axios.put(`${BASE_URL}/api/admin/exercises/${exerciseId}/courses/${courseId}/content`, data);

const updateExerciseQuestions = (exerciseId, courseId, data) => axios.put(`${BASE_URL}/api/admin/exercises/${exerciseId}/course/${courseId}/Questions/Add`, data);

const removeExerciseQuestion = (exerciseId, courseId, data) => axios.put(`${BASE_URL}/api/admin/exercises/${exerciseId}/course/${courseId}/Questions/Remove`, data);

const addExercise = (courseId, data) => axios.post(`${BASE_URL}/api/admin/Exercises/Courses/${courseId}/Create`, data);

const updateExercise = (exerciseId, lessonId, data) => axios.put(`${BASE_URL}/api/admin/exercises/${exerciseId}/courses/${lessonId}/details`, data);

const duplicateExercise = (exerciseId, courseId, data) => axios.put(`${BASE_URL}/api/admin/exercises/${exerciseId}/courses/${courseId}/Duplicate`, data);

const getQuestionPreview = (questionId) => axios.get(`${BASE_URL}/api/Admin/QuestionsBank/${questionId}/Preview`);

const getAdminLaws = (lessonId, data) => axios.post(`${BASE_URL}/api/admin/lawsCollection/courses/${lessonId}`, data);

const updateLaw = (courseId, lawId, data) => axios.put(`${BASE_URL}/api/admin/lawsCollection/courses/${courseId}/update/${lawId}`, data);

const addLaw = (courseId, data) => axios.post(`${BASE_URL}/api/admin/LawsCollection/courses/${courseId}/lessons/add`, data);

const getAdminVerdicts = (lessonId, data) => axios.post(`${BASE_URL}/api/admin/verdictsCollection/courses/${lessonId}`, data);

const updateVerdict = (courseId, verdictId, data) => axios.put(`${BASE_URL}/api/admin/verdictsCollection/courses/${courseId}/update/${verdictId}`, data);

const addVerdict = (courseId, data) => axios.post(`${BASE_URL}/api/admin/verdictscollection/courses/${courseId}/lessons/add`, data);

const getGroups = (lessonId, data) => axios.post(`${BASE_URL}/api/admin/groups/course/${lessonId}/Search`, data);

const deleteGroups = (lessonId, data) => axios.post(`${BASE_URL}/api/admin/groups/course/${lessonId}/delete`, data);

const updateGroup = (lessonId, groupId, data) => axios.put(`${BASE_URL}/api/admin/groups/course/${lessonId}/update/${groupId}`, data);

const addGroup = (courseId, data) => axios.post(`${BASE_URL}/api/admin/groups/course/${courseId}/create`, data);

const assignGroupsToLessons = (courseId, data) => axios.put(`${BASE_URL}/api/Admin/Groups/Course/${courseId}/Lessons/Add`, data);

const assignGroupsToStudents = (courseId, data) => axios.put(`${BASE_URL}/api/Admin/Groups/Course/${courseId}/AddMembers`, data);

const assignGroupsToStudentsList = (courseId, data) => axios.put(`${BASE_URL}/api/Admin/Groups/Course/${courseId}/AddMembersFromList`, data);

const reCalculateGrades = (exerciseId) => axios.put(`${BASE_URL}/api/Admin/Exercises/${exerciseId}/RecalculateGrades`);

const printExercise = (exerciseId, showAnswers = false) => axios.get(`${BASE_URL}/api/Admin/QuestionsBank/ByExercise/${exerciseId}/Preview?withAnswers=${showAnswers}`);

const getExerciseStatistics = (courseId, exerciseId, data) => axios.post(`${BASE_URL}/api/admin/exercises/courses/${courseId}/exercise/${exerciseId}/statistics`, data);

const setHideAnswers = (courseId, exerciseId, bool) => axios.put(`${BASE_URL}/api/admin/exercises/courses/${courseId}/exercise/${exerciseId}/setHideAnswers/${bool}`);

const getExerciseAnswers = (courseId, data, cancelToken) => axios.post(`${BASE_URL}/api/admin/exerciseAnswers/course/${courseId}/Search`, data, {
    cancelToken
  });

const resetUserExercise = (exerciseId, userId) => axios.post(`${BASE_URL}/api/admin/exerciseAnswers/Exercise/${exerciseId}/User/${userId}/Reset`);

const setLessonVideosSortOrder = (data) => axios.put(`${BASE_URL}/api/admin/lessonvideos/setSortOrderToVideos`, data);

const OrganizeLessonVideos = (data) => axios.post(`${BASE_URL}/api/admin/lessonVideos/OrganizeLessonVideos`, data);

const setLessonExercisesSortOrder = (data) => axios.put(`${BASE_URL}/api/admin/exercises/setSortOrderToExercises`, data);

const OrganizeLessonExercises = (data) => axios.post(`${BASE_URL}/api/admin/exercises/OrganizeLessonExercises`, data);

const setLessonLawsCollectionSortOrder = (data) => axios.put(`${BASE_URL}/api/admin/lawscollection/setSortOrderToLawsCollection`, data);

const setExerciseQuestionsSortOrder = (data) => axios.put(`${BASE_URL}/api/admin/exercises/setSortOrderToExerciseQuestions`, data);

const setNewOrderToExerciseQuestions = (exerciseId, data) => axios.put(`${BASE_URL}/api/admin/exercises/${exerciseId}/SortDuplicateColumnQuestions`, data);

const OrganizeLessonLawsCollection = (data) => axios.post(`${BASE_URL}/api/admin/lawscollection/OrganizeLessonLawsCollection`, data);

const setLessonVerdictsCollectionSortOrder = (data) => axios.put(`${BASE_URL}/api/admin/verdictscollection/setSortOrderToVerdictsCollection`, data);

const OrganizeLessonVerdictsCollection = (data) => axios.post(`${BASE_URL}/api/admin/verdictscollection/OrganizeLessonVerdictsCollection`, data);

const setLessonsSortOrder = (data) => axios.post(`${BASE_URL}/api/admin/course/lessons/setSortOrderToLessons`, data);

const organizeLessons = (data) => axios.post(`${BASE_URL}/api/admin/course/lessons/OrganizeLessons`, data);

const deleteLessonInGroup = (groupId, lessonInCourseId) => axios.put(`${BASE_URL}/api/admin/groups/Group/${groupId}/lessonInCourseId/${lessonInCourseId}/remove`);

const deleteStudentInGroup = (groupId, studentId) => axios.put(`${BASE_URL}/api/admin/groups/Group/${groupId}/studentId/${studentId}/remove`);

const deleteEscortInGroup = (groupId, escortId) => axios.put(`${BASE_URL}/api/admin/groups/Group/${groupId}/escortId/${escortId}/remove`);

const assignGroupStudentsToTrack = (groupId, trackId) => axios.post(`${BASE_URL}/api/admin/groups/Group/${groupId}/assignToTrack/${trackId}`);

const unAssignGroupStudentsToTrack = (groupId, trackId) => axios.post(`${BASE_URL}/api/admin/groups/Group/${groupId}/unAssignToTrack/${trackId}`);

const getLessonTypes = () => axios.get(`${BASE_URL}/api/admin/course/lessons/getlessontypes`);

const setColorToLessons = (data) => axios.put(`${BASE_URL}/api/admin/course/lessons/setcolor`, data);

const setStatusToLessons = (data) => axios.put(`${BASE_URL}/api/admin/course/lessons/setStatus`, data);

const setHiddenToLessons = (data) => axios.put(`${BASE_URL}/api/admin/course/lessons/setHidden`, data);

const setStatusToExercises = (data) => axios.put(`${BASE_URL}/api/admin/exercises/setStatus`, data);

const setTypeToExercises = (data) => axios.put(`${BASE_URL}/api/admin/exercises/setType`, data);

export default {
    getLesson,
    updateLesson,
    addLesson,
    getAdminVideos,
    updateVideo,
    getVideoFeedback,
    getAdminBooks,
    getLessonBooks,
    updateCourseBook,
    addLessonVideo,
    addLessonBook,
    getAdminExercises,
    getExercise,
    updateExerciseContent,
    updateExerciseQuestions,
    removeExerciseQuestion,
    addExercise,
    updateExercise,
    duplicateExercise,
    getQuestionPreview,
    getAdminLaws,
    getAdminVerdicts,
    getGroups,
    updateLaw,
    addLaw,
    updateVerdict,
    addVerdict,
    deleteGroups,
    updateGroup,
    addGroup,
    assignGroupsToLessons,
    assignGroupsToStudents,
    assignGroupsToStudentsList,
    reCalculateGrades,
    printExercise,
    getExerciseStatistics,
    setHideAnswers,
    getExerciseAnswers,
    resetUserExercise,
    setLessonVideosSortOrder,
    OrganizeLessonVideos,
    setLessonExercisesSortOrder,
    OrganizeLessonExercises,
    setLessonLawsCollectionSortOrder,
    setExerciseQuestionsSortOrder,
    setNewOrderToExerciseQuestions,
    OrganizeLessonLawsCollection,
    setLessonVerdictsCollectionSortOrder,
    OrganizeLessonVerdictsCollection,
    setLessonsSortOrder,
    organizeLessons,
    deleteLessonInGroup,
    deleteStudentInGroup,
    deleteEscortInGroup,
    assignGroupStudentsToTrack,
    unAssignGroupStudentsToTrack,
    getLessonTypes,
    setColorToLessons,
    setStatusToLessons,
    setHiddenToLessons,
    setStatusToExercises,
    setTypeToExercises
};
